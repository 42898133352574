<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/banner' }">滚动图</el-breadcrumb-item>
      <el-breadcrumb-item>编辑滚动图</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.pageForm.title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" ref="formRef" :model="pageForm" label-width="120px">
        <el-form-item label="排序">
          <el-input type="number" v-model="pageForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="滚动图标题">
          <el-input v-model="pageForm.title" placeholder="请输入滚动图标题"></el-input>
        </el-form-item>
        <el-form-item label="滚动图类型">
          <el-radio-group v-model="pageForm.type">
            <el-radio :label="1">首页</el-radio>
            <el-radio :label="2">产品与服务列表</el-radio>
            <el-radio :label="3">客户案例</el-radio>
            <el-radio :label="4">新闻资讯</el-radio>
            <el-radio :label="5">下载中心</el-radio>
            <el-radio :label="6">产品价格</el-radio>
            <el-radio :label="7">关于我们</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="滚动图">
          <upload :url="pageForm.url" :filed="'url'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">修改设置</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
export default {
  name: 'Edit',
  components: {
    Upload
  },
  data () {
    return {
      pageForm: {
        id: this.$route.params.id,
        sort: 0,
        title: '',
        type: 1,
        url: ''
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const { data: res } = await this.$http.get('/pc-banner', { params: { id: this.$route.params.id } })
      if (res.status === 200) {
        this.pageForm.sort = res.data.sort
        this.pageForm.title = res.data.title
        this.pageForm.type = res.data.type
        this.pageForm.url = res.data.url
      } else {
        this.$message.error(res.msg)
      }
    },
    async onSubmit () {
      if (!this.pageForm.url) {
        this.$message.error('请上传滚动图')
        return false
      }
      const { data: res } = await this.$http.put('/pc-banner', this.pageForm)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.$router.push('/admin/apps/pc/banner')
      } else {
        this.$message.error(res.msg)
      }
    },
    cancel () {
      this.$router.push('/admin/apps/pc/banner')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.middle-form{
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
/deep/.el-radio{
  margin-bottom: 14px;
}
</style>
